<template>
	<div class="orderresult-a">
		<ul class="orderresult-b orderresult-c">
			<li :class="(item.current == true ? 'current ' : '') + 'orderresult-d  orderresult-c'" v-for="(item, index) in progress"
			 :key="index">
				<span class="orderresult-e"></span>
				<a class="orderresult-f">{{ item.name }}</a>
			</li>
		</ul>
		<div class="orderresult-aa">
			<div class="orderresult-ab">保险信息</div>
			<block v-if="BookInsuranceInfo != ''">
				<block v-for="(item, index) in BookInsuranceInfo" :key="index">
					<div class="orderresult-ba">
						<span class="confirm-bb" :title="item.ProductName">{{ item.ProductName }}</span>
						<span class="orderresult-bc">{{ item.EffectiveStart }} 至 {{ item.EffectiveEnd }}</span>
						<span class="orderresult-bd">有效期：{{ item.Days }}天</span>
					</div>

					<el-row class="orderresult-ca" type="flex" justify="space-between" align="middle">
						<div class="orderresult-cc">
							<p>
								<span class="orderresult-cd">
									<i class="orderresult-cii">成人价格：￥{{ priceInfo.AdultPrice }}*</i>
									<i class="orderresult-ci">{{ priceInfo.AdultCount }}</i>
								</span>
								<!-- <span>
									同行价格：￥
									<b class="orderresult-jq">{{ priceInfo.AgentADTPrice}}</b>
									*
									<i class="orderresult-cic">{{ priceInfo.AdultCount }}</i>
								</span> -->
							</p>

							<p>
								<span class="orderresult-cp">
									<i class="orderresult-cii">儿童价格：￥{{ priceInfo.ChildPrice }}*</i>
									<i class="orderresult-ci">{{ priceInfo.ChildCount }}</i>
								</span>
								<!-- <span>
									同行价格：￥
									<b class="orderresult-jq">{{ priceInfo.AgentChildPrice}}</b>
									*
									<i class="orderresult-cic">{{ priceInfo.ChildCount }}</i>
								</span> -->
							</p>

							<!-- <p>
							<span class="orderresult-cl">
								<i class="orderresult-cii">老人价格：￥90*</i>
								<i class="orderresult-ci">0</i>
							</span>
							<span>
								同行价格：￥
								<b class="orderresult-jq">0</b>
								*
								<i class="orderresult-cic">0</i>
							</span>
						</p> -->
						</div>

						<span class="orderresult-ce">
							同行价总金额：
							<b class="orderresult-jq">￥{{ priceInfo.TotalPrice}}</b>
						</span>
					</el-row>
				</block>
			</block>
		</div>

		<div class="orderresult-aab">
			<div class="orderresult-aaa">其他旅客</div>

			<div class="orderresult-xm">
				<table class="table orderresult-xmm pessenger-list pessenger-list-head ">
					<tbody>
						<tr>
							<td style="width: 120px;"></td>
							<td class="p-td01">姓名</td>
							<td class="p-td02">类型</td>
							<td class="p-td12">国籍</td>
							<td class="p-td12">出生日期</td>
							<td class="p-td03">证件类型</td>
							<td class="p-td04">证件号</td>
							<td class="p-td08">国家区号-手机号码</td>
						</tr>
					</tbody>
				</table>

				<table class="orderresult-lk">
					<tbody>
						<tr v-for="(item, index) in form.PassengerListData" :key="index">
							<td style="width: 120px; text-align: right;">{{ getInsuranceRoleName(item.InsuranceRole) }}</td>
							<td class="p-td01">{{ item.Man_xingming }}</td>
							<td class="p-td02">{{ item.Man_type_p == 'ADT' ? '成人' : '儿童' }}</td>
							<td class="p-td12">{{ item.Nationality }}</td>
							<td class="p-td12">{{ item.Birthday }}</td>
							<td class="p-td03">{{ getCerTypeName(item.CertificateType) }}</td>
							<td class="p-td04">{{ item.CertificateNo }}</td>
							<td class="p-td08">{{ item.Area }} {{ item.Phone }}</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- <div class="orderresult-aaa">其他信息</div> -->

			<div class="orderresult-aaa">联系人信息</div>
			<el-row class="orderresult-lcq" type="flex" justify="space-between" style="padding-right:120px">
				<span>联系人：{{ form.linkman }}</span>
				<span>国家区号-手机号码：{{ form.area }}-{{ form.phone }}</span>
				<span>联系邮箱：{{ form.email }}</span>
			</el-row>

			<!-- <div class="orderresult-aaa">{{ form.remark }}</div> -->
			<div class="orderresult-aaa">附加信息</div>
			<div class="orderresult-lcqq">{{ form.remark }}</div>
		</div>

		<div class="orderresult-qd">
			<!-- 确定预定 -->
			<form>
				<div class="orderresult-qda">
					<div class="orderresult-qdb">
						<div class="orderresult-qdc">
							<p class="orderresult-qde">
								订单总金额：
								<span class="orderresult-qdf">
									￥
									<i>{{ priceInfo.TotalPrice}}</i>
								</span>
								<span class="orderresult-qdg">
									共
									<i>{{ priceInfo.AdultCount + priceInfo.ChildCount }}</i>
									名旅客
								</span>
							</p>
							<div class="orderresult-qdh">
								<em>请选择结算方式：</em>
								<el-radio-group v-model="payTypeID">
									<el-radio v-for="(item, index) in payTypeList" :key="index" :label="item.value" :value="item.value">
										{{ item.name }}
										<i class="gray9 ft12 ml10" v-if="item.balanceName != ''">{{ item.balanceName }}:￥{{ item.balance }}</i>
									</el-radio>
								</el-radio-group>

								<!-- <div class="orderresult-qds"><el-radio v-model="radio" label="1" class="orderresult-qdss">现付</el-radio></div>
								<div class="orderresult-qds"><el-radio v-model="radio" label="2" class="orderresult-qdss orderresult-left">月结</el-radio></div>
								<span class="orderresult-ye">
									企业额度剩余：¥
									<i>0</i>
								</span>
								<div class="orderresult-qds"><el-radio v-model="radio" label="3" class="orderresult-qdss orderresult-left">现金账户</el-radio></div>
								<span class="orderresult-ye">
									账户余额：¥
									<i>0</i>
								</span> -->
							</div>
						</div>

						<div class="orderresult-qdd">
							<a class="ft16 fb gray3 mr20 btn" href="javascript:;" @click="bindReturn">返回</a>
							<el-button type="danger" class="orderresult-qddd" @click="bindSubmit">确定预订</el-button>
						</div>
					</div>
				</div>
			</form>
		</div>
		<desModal :titleRight="showWorkerValue" widthNum="40%" :footerNone="true" :showClose="true" @clickHeadAction="showWorkernone"
		 class="hhh" @clickDelAction="showWorkernone" :delShow="true">
			<el-table-column>
				<div class="orderresult-icona">
					<i class="orderresult-icon iconfont icon-wenhao"></i>
					确认使用月结方式支付吗？
				</div>
			</el-table-column>
		</desModal>
	</div>
</template>

<script>
	import desModal from '../../components/desModal.vue';

	import {
		payTypeList
	} from '@/data/staticData';
	import {
		submitOrder
	} from '@/api/order';

	import {
		getInsurSearchInfo,
		setInsurSearchInfo,
		getAccountInfo,
		getBookingFormInfo,
		setBookingFormInfo
	} from '@/utils/localdatas';

	var util = require('@/utils/util.js');
	var datehelper = require('@/utils/datehelper.js');
	var destApi = require('@/api/destApi.js'); //目的地
	var insApi = require('@/api/insApi.js'); //保险接口
	var memberApi = require('@/api/memberApi.js');
	var that;
	export default {
		components: {
			desModal
		},
		data() {
			return {
				radio: '1',
				showWorkerValue: false,
				progress: [{
						name: '选择保险',
						current: true
					},
					{
						name: '填写资料',
						current: true
					},
					{
						name: '确定订单',
						current: true
					},
					{
						name: '预订成功'
					}
				],
				payTypeList: payTypeList,
				payTypeID: 1,
				priceInfo: {
					TotalPrice: 0,
					AdultPrice: 0,
					AdultTax: 0,
					ChildPrice: 0,
					ChildTax: 0,
					RCDPrice: 0,
					
					AgentADTPrice:0,
					AgentCHDPrice:0,
					AgentRCDPrice:0,
					AgentRTotalPrice:0,
					
					AdultCount: 1,
					ChildCount: 0,
					Discount: 1
				},
				accountInfo: getAccountInfo(),
				form: getBookingFormInfo(),
				BookInsuranceInfo: ''
			};
		},
		//创建
		created() {
			that = this;
			this.$router.push({
				path: '/'
			}); 
			getBookerInsuranceInfo();
			getLedgerAccountCash();
			$(window).scrollTop("0");
		},
		//方法
		methods: {
			bindReturn(e) {
				that.$router.push({ path: '/Insurance/booking' });
			},
			getCerTypeName(cerType) {
				return util.getCerTypeName(cerType);
			},
			getInsuranceRoleName(val) {
				return util.getInsuranceRoleName(val);
			},
			showWorker() {
				this.showWorkerValue = true;
			},
			showWorkernone() {
				console.log(1);
				this.showWorkerValue = false;
				console.log(this.showWorkerValue);
			},
			//提交订单
			bindSubmit: function(e) {
				var values = that.form;

				console.log(values);
				let selectInsList = JSON.stringify(that.BookInsuranceInfo);

				let PassengerList = JSON.stringify(values.PassengerListData);

				let accountinfo = '';
				if (util.checkLogin()) {
					accountinfo = JSON.stringify(getAccountInfo());
				}
				let parm = {
					//yqfkey: util.getSessionKey(),
					selectInsList: selectInsList,
					PassengerList: PassengerList,
					// InsurancelList: InsurancelList,
					// selectCoupon: selectCoupon,
					accountinfo: accountinfo,

					// isneedinsure: values.isneedinsure,
					SendLinkMan: values.linkman,
					SendLinkMobile: values.phone,
					SendLinkEmail: values.email,
					SendLinkRemark: values.remark,

					IsChinaCity: true,
					// isbingPhone: that.isbingPhone,
					// VCode: values.VCode,
					payTypeID: that.payTypeID,
					ordertype: 'ins',
					isus: 'true'
				};
				console.log(parm);
				// return;

				const loading = this.$loading({
					lock: true,
					text: '订单提交中',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});

				submitOrder(parm, function(result) {
					if (result.code == 0) {
						that.$router.push({
							path: '/Order/orderResult',
							query: {
								id: result.data.LR_BillNO
							}
						});	
						
						// let url = '/pages/flight/orderResult/orderResult?id=' + result.data.LR_BillNO + '&payTypeID=' + that.payTypeID;
						// uni.reLaunch({
						// 	url: url
						// });
						// that.$router.push({ path: '/Booking/result', query: { id: result.data.LR_BillNO, payTypeID: that.payTypeID } });
						// that.$router.push({
						// 	path: '/IntlAir/success',
						// 	query: {
						// 		id: result.data.LR_BillNO,
						// 		payTypeID: that.payTypeID
						// 	}
						// });
					} else if (result.code == -1) {
						that.$confirm(result.msg, '提示', {
							distinguishCancelAndClose: true,
							confirmButtonText: '确定'
						}).then(() => {
							that.$router.push({
								path: '/'
							});
						});
					} else {
						that.$alert(result.msg);
					}
					loading.close();
				});
			}
		}
	};

	function getBookerInsuranceInfo() {
		var parm = {};
		insApi.GetBookerInsuranceInfo(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				let BookInsuranceInfo = result.data.BookInsuranceInfo;
				let priceInfo = that.priceInfo;

				that.BookInsuranceInfo = BookInsuranceInfo;

				priceInfo.AdultCount = 1;
				priceInfo.AdultPrice = BookInsuranceInfo[0].ADTPrice;
				priceInfo.ChildPrice = BookInsuranceInfo[0].CHDPrice;
				priceInfo.RCDPrice = BookInsuranceInfo[0].RCDPrice;
				priceInfo.AgentADTPrice = BookInsuranceInfo[0].AgentADTPrice;
				priceInfo.AgentCHDPrice = BookInsuranceInfo[0].AgentCHDPrice;
				priceInfo.AgentRCDPrice = BookInsuranceInfo[0].AgentRCDPrice;
				priceInfo.Discount = result.data.agentDiscount;

				that.priceInfo = priceInfo;
				getPassengerList();
			} else {
				that.$alert(result.msg, {
					confirmButtonText: '确定',
					callback: action => {
						that.$router.push({
							path: '/Insurance/list'
						});
					}
				});
			}
		});
	}

	//旅客信息
	function getPassengerList() {
		let form = getBookingFormInfo();

		if (util.isNullOrEmpty(form)) {
			memberApi.GetPassengerList({}, function(result) {
				// console.log(result);;
				if (result.code == 0) {
					let PassengerListData = JSON.parse(result.data.PassengerList);
					console.log(PassengerListData);
					that.form.PassengerListData = PassengerListData;
					setPriceInfo();
				}
			});
		} else {
			that.form = form;
			setPriceInfo();
		}
	}

	function setPriceInfo() {
		let priceInfo = that.priceInfo;
		let PassengerList = that.form.PassengerListData;
		let adultCount = 0;
		let childCount = 0;
		
		let AgentADTPrice = priceInfo.AgentADTPrice;
		let AgentCHDPrice = priceInfo.AgentCHDPrice;
		let AgentRCDPrice = priceInfo.AgentRCDPrice;
		
		let TotalPrice = parseInt(priceInfo.TotalPrice);
		let AdultTotalPrice = parseInt(priceInfo.AdultPrice) + parseInt(priceInfo.AdultTax);
		let ChildTotalPrice = parseInt(priceInfo.ChildPrice) + parseInt(priceInfo.ChildTax);

		for (var i = 0; i < PassengerList.length; i++) {
			if (PassengerList[i].Man_type_p == 'CHD') {
				childCount++;
			} else {
				adultCount++;
			}
		}

		AgentADTPrice = AgentADTPrice * adultCount;
		AgentCHDPrice = AgentCHDPrice * childCount;

		TotalPrice = AgentADTPrice + AgentCHDPrice;

		priceInfo.AdultCount = adultCount;
		priceInfo.ChildCount = childCount;
		priceInfo.TotalPrice = TotalPrice;
		that.priceInfo = priceInfo;
	}

	//现金账户信息
	function getLedgerAccountCash() {
		memberApi.GetLedgerAccountCash({
				OwnerCode: that.accountInfo.CompanyCode
			},
			function(result) {
				// console.log(result);;

				if (result.code == 0) {
					if (result.data.LedgerAccountCashs.length != 0) {
						let LedgerAccountCashs = result.data.LedgerAccountCashs;
						let payTypeList = that.payTypeList;
						payTypeList[1].balance = LedgerAccountCashs[1].AvailableBalance;
						payTypeList[2].balance = LedgerAccountCashs[0].AvailableBalance;
						console.log(LedgerAccountCashs);
						that.payTypeList = payTypeList;
					}
				}
			}
		);
	}
</script>

<style lang="scss">
	@import '../../style/Insurance/orderresult.scss';
</style>
